import * as React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import Table from '../components/Table';

const PremiumBundlesPage = ({ data, location }) => {
  const title = 'Matomo premium bundles';
  return (
    <Layout location={location} title={title}>
      <h1>{`Premium bundles (${data.bundlesPayed.nodes.length}`})</h1>
      {data?.bundlesPayed?.nodes &&
        Table({
          data: data.bundlesPayed.nodes,
          downloads: false,
          idPath: false,
        })}
    </Layout>
  );
};

export const query = graphql`
  {
    bundlesPayed: allMatomoPlugin(
      sort: { order: DESC, fields: lastUpdated }
      filter: { isPaid: { eq: true }, isBundle: { in: true } }
    ) {
      nodes {
        id
        idPath
        latestVersion
        lastUpdated
        isPaid
        isCustomPlugin
        createdDateTime
        description
        displayName
        homepage
        bundle {
          plugins {
            name
          }
        }
      }
    }
  }
`;

export default PremiumBundlesPage;
